import { API_ROUTE_FULL_PATHS } from "../utils/constants/routes/apiPaths";
import handleRequestErr from "../utils/helperFunctions/handleRequestErr";
import handleRequestFinally from "../utils/helperFunctions/handleRequestFinally";
import { ThemeData } from "../utils/types/services/theme";
import api from "./api";

const STATUS_OK = 200;

export const getTheme = async (
  currentCancerType: string,
): Promise<{
  success: boolean;
  data?: ThemeData;
}> => {
  let result = null;
  try {
    result = await api.get(
      `${API_ROUTE_FULL_PATHS.mainContentGetTheme}${currentCancerType}`,
    );
    if (result.status === STATUS_OK) {
      return {
        success: true,
        data: result.data.data,
      };
    }

    throw new Error("no other success status will be returned excpet 200");
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally({ result });
  }
};
