import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";

const BaseButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  fontWeight: "medium",
  height: "60px",
  width: "130px",
  fontSize: "18px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "&:disabled": {
    border: "none",
    backgroundColor: "#EAE8E5",
    color: "#C3BAB5",
  },
}));

export default BaseButton;
