import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import SelectBaseButton from "../shareComponents/button/SelectBaseButton";
import useButtonCSSHook from "./ButtonCSSHook";
import { OptionSet } from "../../utils/types/services/optionSet";
import DOMPurify from "dompurify";
import QuestionSet from "../../utils/types/ui/questionForm";

interface SupportTypeFormProps {
  data: number[];
  updateAnswer8: (data: number[]) => void;
  optionSet: QuestionSet[]| undefined;
}


const SupportTypeForm = (props: SupportTypeFormProps) => {
  const { updateAnswer8, data } = props;
  const { selectedBtn, defaultBtn } = useButtonCSSHook();


  const questionSet = props.optionSet
    ? props.optionSet.filter((option) => option.formStep === 8)
    : [];

  return (
    <Grid sx={{ width: "100%" }}>
      <Grid container item justifyContent="center">
        <Typography
          variant="h4"
          sx={{ color: "black", padding: "0px 0 3px 0" }}
        >
          你想獲得誰的支援?
        </Typography>
      </Grid>
      <Grid container item justifyContent="center">
        <Typography
          variant="h5"
          sx={{ color: "#999999", padding: "0px 0 20px 0" }}
        >
          (請最多選擇2項)
        </Typography>
      </Grid>
      {questionSet.map((items, index) => (
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          key={index}
          sx={{ p: 1 }}
        >
          <SelectBaseButton
            onClick={() => {
              const oldData = [...data];
              const oldDataIndex = oldData.findIndex((e) => e === items.value);

              if (oldDataIndex >= 0) {
                oldData.splice(oldDataIndex, 1);
                updateAnswer8(oldData);
                return;
              }
              if (oldData.length > 1) return;
              if (oldDataIndex === -1) {
                oldData.push(items.value);
                updateAnswer8(oldData);
              }
            }}
            sx={data.includes(items.value) ? selectedBtn : defaultBtn}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexFlow: "row nowrap",
              }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(items.description) }}
            />
          </SelectBaseButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default SupportTypeForm;
