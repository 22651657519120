import { Grid, Typography } from "@mui/material";
import SelectBaseButton from "../shareComponents/button/SelectBaseButton";
import QuestionSet from "../../utils/types/ui/questionForm";
import useButtonCSSHook from "./ButtonCSSHook";
import DOMPurify from "dompurify";

const NUMBER_9 = 9;

interface KindofSupportFormProps {
  data: number[];
  updateAnswer9: (data: number[]) => void;
  optionSet: QuestionSet[] | undefined;
}


const KindofSupportForm = (props: KindofSupportFormProps) => {
  const { selectedBtn, defaultBtn } = useButtonCSSHook();
  const { updateAnswer9, data } = props;

  const questionSet = props.optionSet
    ? props.optionSet.filter((option) => option.formStep === NUMBER_9)
    : [];
  return (
    <Grid sx={{ width: "100%" }}>
      <Grid container item justifyContent="center">
        <Typography
          variant="h4"
          sx={{ color: "black", padding: "0px 0 3px 0" }}
        >
          你最想得到的支援方式?
        </Typography>
      </Grid>
      <Grid container item justifyContent="center">
        <Typography
          variant="h5"
          sx={{ color: "#999999", padding: "0px 0 20px 0" }}
        >
          (請最多選擇2項)
        </Typography>
      </Grid>
      {questionSet.map((items, index) => (
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          key={index}
          sx={{ p: 1 }}
        >
          <SelectBaseButton
            onClick={() => {
              const oldData = [...data];
              const oldDataIndex = oldData.findIndex((e) => e === items.value);

              if (oldDataIndex >= 0) {
                oldData.splice(oldDataIndex, 1);
                updateAnswer9(oldData);
                return;
              }
              if (oldData.length > 1) return;
              if (oldDataIndex === -1) {
                oldData.push(items.value);
                updateAnswer9(oldData);
              }
            }}
            sx={data.includes(items.value) ? selectedBtn : defaultBtn}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexFlow: "row nowrap",
              }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(items.description) }}
            />
          </SelectBaseButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default KindofSupportForm;
