import React from 'react';
import { Card, CardContent, Typography, Link as MuiLink } from '@mui/material';

function Footer() {
  return (
    <footer>
      <Card variant="outlined" sx={{ backgroundColor: '#F6F6F6' }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', fontSize:'15px'}}>
          <div>
            <MuiLink href="https://www.roche.com.hk/zh/home" color="inherit" style={{ marginRight: '16px' }}>
              關於我們
            </MuiLink>
            {' | '}
            <MuiLink href="https://www.roche.com.hk/zh/privacy-statement" color="inherit" style={{ marginRight: '16px' }}>
              私隱聲明
            </MuiLink>
            {' | '}
            <MuiLink href="https://www.roche.com.hk/zh/legal-statement" color="inherit" style={{ marginRight: '16px' }}>
              法律聲明
            </MuiLink>
          </div>
        </div>
        <CardContent>
          <Typography variant="body2" style={{ fontSize: '10px' }}>
            本網站提供的資訊僅供教育用途，並不能取代醫生的意見。
            如果您有個人健康、治療或診斷上的問題，請諮詢您的醫生。
          </Typography>
          <Typography variant="body2" style={{ fontSize: '10px' }}>
            羅氏大藥廠香港有限公司不能回答有關一般醫學上或是您個人健康狀況的問題。
          </Typography>
          <Typography variant="body2" style={{ fontSize: '10px' }}>
            本網站所載之產品資訊乃供廣泛使用者使用或瀏覽，其中某些產品詳情或資料可能於閣下所處的國家無法得到或為無效。
          </Typography>
          <Typography variant="body2" style={{ fontSize: '10px' }}>
            請注意，若獲取該等資料不符合閣下所處的國家的法律程式、規定、註冊或使用，本公司不會承擔任何責任。
          </Typography>
          <Typography variant="body2" style={{ fontSize: '10px' }}>
            安全報告免責聲明
            此平台並不旨在用於記錄或報告不良藥物事件資訊。如您懷疑有任何副作用，
            或有任何與我們產品相關的問題，
            請向您的醫護人員諮詢，及將有關資訊發送到羅氏大藥廠藥物安全部門:
            (a) 電郵:<a href="mailto:hong_kong.drug_safety@roche.com">hong_kong.drug_safety@roche.com</a>, 或 (b) 電話: +852 2733 47111。
          </Typography>
        </CardContent>
      </Card>
      <div className="copyright">
        <p style={{ fontSize: '10px',textAlign: 'center'}}>
          &copy; {new Date().getFullYear()} 
          <a href="https://hkcancer101.com/lungcancer" target="_blank"
           rel="noopener noreferrer">hkcancer101.com
           </a> - ROCHE HONG KONG LIMITED ALL RIGHTS RESERVED
        </p>
      </div>
      <div className="regulatory-notice" style={{ fontSize: '10px', textAlign: 'center', marginTop: '10px' }}>
  <p>
  M-HK-00001636 valid until 15/06/2026 or until change is required in accordance with the regulatory requirements, whichever comes first
  </p>
</div>

    </footer>
  );
}

export default Footer;

