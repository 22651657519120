import axios from "axios";

const TIMEOUTS = {
  PRODUCTION: 15000,  // 15 seconds for production
  DEVELOPMENT: 60000, // 60 seconds for development
};

const api = axios.create({
  withCredentials: process.env.NODE_ENV === "production" ? undefined : true,
  baseURL:
    process.env.NODE_ENV === "production"
      ? undefined
      : process.env.REACT_APP_API_URL,
  timeout: process.env.NODE_ENV === "production" ? TIMEOUTS.PRODUCTION
  : TIMEOUTS.DEVELOPMENT,
});

export default api;
