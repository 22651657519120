class Logger {
    static info(message: string | object, ...optionalParams: any[]) {
      if (process.env.NODE_ENV !== 'production') {
        console.info(message, ...optionalParams); // Info logs for non-production environments
      }
    }
  
    static warn(message: string | object, ...optionalParams: any[]) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(message, ...optionalParams); // Warning logs for non-production environments
      }
    }
  
    static error(message: string | object, ...optionalParams: any[]) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(message, ...optionalParams); // Error logs for non-production environments
      }
    }
  }
  
  export default Logger;
  