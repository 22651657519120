import { Grid, Typography } from "@mui/material";
import SelectBaseButton from "../shareComponents/button/SelectBaseButton";
import useButtonCSSHook from "./ButtonCSSHook";
import DOMPurify from "dompurify";
import { OptionSet } from "../../utils/types/services/optionSet";

interface PatientorCaregiverProps {
  data: number[];
  updateAnswer1: (data: number[]) => void;
  optionSet: OptionSet[] | undefined;
}

const PatientorCaregiverForm = (props: PatientorCaregiverProps) => {
  const { updateAnswer1, data } = props;

  const { selectedBtn, defaultBtn } = useButtonCSSHook();

  const questionSet = props.optionSet
    ? props.optionSet.filter((option) => option.formStep === 1)
    : [];

  const handleOptionClick = (value: number) => {
    if (data.includes(value)) {
      updateAnswer1([]);
    } else {
      updateAnswer1([value]);
    }
  };

  return (
    <Grid sx={{ width: "100%" }}>
      <Grid container item justifyContent="center">
        <Typography variant="h4" sx={{ color: "black", padding: "0 0px 0 3px 0" }}>
          你是病人或照顧者?
        </Typography>
      </Grid>
      {questionSet.map((items, index) => (
        <Grid container item justifyContent="center" xs={12} key={index} sx={{ p: 1 }}>
          <SelectBaseButton
            onClick={() => handleOptionClick(items.value)}
            sx={data.includes(items.value) ? selectedBtn : defaultBtn}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexFlow: "row nowrap",
              }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(items.description) }}
            />
          </SelectBaseButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default PatientorCaregiverForm;
