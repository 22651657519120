import { API_ROUTE_FULL_PATHS } from "../utils/constants/routes/apiPaths";
import handleRequestErr from "../utils/helperFunctions/handleRequestErr";
import { UserResult } from "../utils/types/services/userResult";
import api from "./api";
import ReactGA from "react-ga4";
import { AxiosResponse } from "axios";

const STATUS_OK = 200;
export const sendSurveyResult = async (
 userId: number,
 email: string,
 name: string,
 consentChecked: boolean,
): Promise<{ result: UserResult | undefined }> => {
 try {
   const response: AxiosResponse<UserResult> = await api.post(
     API_ROUTE_FULL_PATHS.testsendSurveyResults,
     {
       userId,
       email,
       name,
       consentChecked,
     }
   );


   if (response.status === STATUS_OK) {
     return {
       result: response.data,
     };
   } else {
     throw new Error("Unexpected status code: " + response.status);
   }
 } catch (error) {
   handleRequestErr(error);
   throw error;
 }
};



