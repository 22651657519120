import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Grid, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

type Anchor = "bottom";

interface DisclaimerDrawerProps {
  title: string;
  color: string; // Add color prop
}

const DisclaimerDrawer: React.FC<DisclaimerDrawerProps> = ({ title, color }) => {
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <>
      {(["bottom"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Typography
            component={"span"}
            onClick={toggleDrawer(anchor, true)}
            sx={{
              color: color, // Use the passed color prop here
              display: "inline-block",
              textDecoration: "underline",
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
          >
            {title}
          </Typography>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Grid
              sx={{
                padding: {
                  xs: "3% 5% 0 5%",
                  md: "3% 10% 0 10%",
                  lg: "3% 20% 0 20%",
                },
              }}
            >
              <Grid container justifyContent="space-between">
                <Typography
                  variant="h5"
                  sx={{ color: "black", fontWeight: 900, paddingBottom: 2 }}
                >
                  癌症支援平台免責聲明
                </Typography>
                <CloseRoundedIcon
                  sx={{
                    fontSize: 40,
                    color: "#706B69",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={toggleDrawer(anchor, false)}
                />
              </Grid>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 0.3 }}
              >
                Disclaimer
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
                The information provided during the “Resources Matching
                Self-Assessment” serves as reference only. It should not be
                treated as a substitute for the medical advice of your own
                doctor or any other Healthcare Professionals. Please consult the
                relevant qualified Healthcare Professional in relation to your
                medical condition, for therapeutic advice or for medical product
                information. No information provided is for medicine marketing
                and constitutes a medical advertisement. This assessment has no
                intention of inappropriately induce, influence or reward the
                past, present or future prescribing, supply, purchasing,
                recommending or use of any Roche product (including formulary
                recommendations). All community resources recommended in this
                program are based on the responses of this assessment and what
                is publicly available to the best knowledge of Roche. Roche
                bears no liability for the quality and content of services
                provided by these organisations.
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 0.3 }}
              >
                免責聲明
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
                癌症支援平台中所提供的資訊僅供參考，該資訊不應替代你醫生或任何其他專業醫療人員的建議。
                就有關治療的建議或醫療產品的資訊，請諮詢相關及合資格的專業醫療人員。
                本計劃所提供的任何資訊均不用於藥品營銷並不屬醫療廣告。
                此計劃無意不當誘導、影響或獎勵過去、現在或未來對任何羅氏產品(包括處方推薦)的處方、供應、購買、推薦或使用。
                本計劃中提供的所有社區資源均基於此評估表的結果以及羅氏對該組織公開資訊的了解。
                羅氏對這些組織提供的服務的質量和內容不承擔任何責任。
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 0.3 }}
              >
                Safety Reporting Disclaimer
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              This program is neither intended nor designed to record or report adverse event information. 
              If you have a suspected side effect or problem to report regarding one of our products, 
              please discuss with your treating Healthcare Professional 
              and send the information to the Roche Patient Safety team via: (a) Email: 
<a href="mailto:hong_kong.drug_safety@roche.com">hong_kong.drug_safety@roche.com</a>
 or (b) Phone: +852 2733 4711. Roche has legal obligation to collect and
report potential adverse events brought to its attention to Health Authorities. In such cases,
your data will be processed with greatest care and diligence in accordance with specific 
GVP (Pharmacovigilance) legislation, as described in the Privacy Policy related to pharmacovigilance.
The personal data collected during this program will be solely used for the purpose consented in this
Form. To learn more about Roche privacy policy, please visit: 
<a href="https://www.roche.com.hk/en/privacy.html">https://www.roche.com.hk/en/privacy.html</a>.

              </Typography>

              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 0.3 }}
              >
                安全報告免責聲明
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              本計劃並不旨在用於記錄或報告不良藥物事件資訊。如您懷疑有任何副作用,或有任何與我們產品相關的問題,請向
您的醫護人員諮詢,及將有關資訊發送到羅氏大藥廠藥物安全部門: (a) 電郵: 
<a href="mailto:hong_kong.drug_safety@roche.com">hong_kong.drug_safety@roche.com</a>,
或 (b) 電話: +852 2733 4711。羅氏有法律義務收集並向有關的政府部門報告其藥物潛在的不良事件。在這種情況下
,您的個人資料將根據藥物警戒相關的隱私政策中描述的特定GVP (藥物警戒) 法例,以最謹慎的方式處理。本計劃期
間收集的個人數據將僅用於本表格中同意的目的。欲了解更多羅氏隱私政策,請游覽: 
<a href=" https://www.roche.com.hk/en/privacy.html">https://www.roche.com.hk/en/privacy.html</a>.
              </Typography>
            </Grid>
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
};

export default DisclaimerDrawer;
