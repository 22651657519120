import { AxiosResponse } from "axios";
import { CategoryType, LabelType } from "../utils/constants/ga";
import { API_ROUTE_FULL_PATHS } from "../utils/constants/routes/apiPaths";
import handleRequestErr from "../utils/helperFunctions/handleRequestErr";
import { UserContactProps } from "../utils/types/services/userContact";
import api from "./api";
import ReactGA from "react-ga4";

const STATUS_OK = 200;

const handleRequestFinally = (result: AxiosResponse | null) => {
  if (result === null) return;
  ReactGA.event({
    category: CategoryType.Axios,
    action: result.config.url ?? "",
    label: LabelType.submittedUserContact,
    value: result.status,
  });
};

export const addUserContact = async (
  userSubmitResult: {
    userContact: UserContactProps;
  },
  currentCancerType: string,
): Promise<{
  result: {
    userContact: UserContactProps;
  };
}> => {
  let result = null;
  try {
    result = await api.post(
      `${API_ROUTE_FULL_PATHS.testFormPostUserContact}${currentCancerType}`,
      userSubmitResult,
    );
    if (result.status === STATUS_OK) {
      return {
        result: userSubmitResult,
      };
    }

    throw new Error("no other success status will be returned excpet 200");
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally(result);
  }
};
